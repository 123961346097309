
<template>
  <v-app>
    <div class="row justify-center">
      <div class="col-xl-3 col-md-3 ">
        <v-card raised>
          <div class="d-flex flex-no-wrap justify-center">
            <div>
              <v-card-subtitle>
                <progressive-img width="150" all position="center" class="img-fluid"  contain src="assets/media/illustrations/undraw_confirmation_2uy0.svg"></progressive-img>
                 <!-- <v-img height="500" all position="center"  contain src="assets/media/illustrations/undraw_confirmed_81ex.svg"></v-img> -->
                <h2 class="text-center mt-5 text-success">Order Success</h2>
                <p>Thanks you for your order.  Your payment has been processed.
                </p>
                <p>Your subscription plans and domains registration will be activated within next
                  10-15 minutes.</p>
                  <p> You will receive a separate email confirmation once the plans are activated.</p>
                <router-link to="/websites" class="btn btn-primary text-white">Click here to return to dashboard</router-link>
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Success " }]);
  },
  beforeUpdate() {},
  components: {
    KTPortlet
  },
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style>
.payment-list a {
  display: block;
  position: relative;
  padding: 10px;
  border: 1px solid #e5f3ff;
  border-radius: 5px;
  text-align: center;
  min-height: 74px;
  transition: all 300ms;
}
.payment-list a:hover {
  background: #e5f3ff;
}
.payment-list a > img {
  width: 130px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.payment-list .offline-payments > img {
  width: 200px;
}
</style>
